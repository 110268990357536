import 'bootstrap/dist/css/bootstrap.min.css';


function SECCION_UNO() {
  return (

    <section className="seccion_uno" id='quienes'>
      <div className="container">

        <div>
          <div  className="wow fadeInUp animated">
            <h4 className="title" tabIndex="0" alt="quines somos">
              ¿Quiénes somos?
            </h4>
            <hr></hr>
          </div>
        </div>

        <div className='contePes'>
          <div>
            <p className='wow fadeInUp animated' tabIndex="0">
            Somos un equipo de profesionales de las áreas de Marketing & Diseño, Desarrollo y Comercialización de Negocios, especializados en el universo de las franquicias.
            </p>
            <p className='wow fadeInUp animated' tabIndex="0">
            Contamos con más de 10 años de expertise en estas áreas y hemos aprendido a cómo gestionarlas eficazmente, pero lógicamente este aprendizaje es continuo, ya que vivimos en un contexto dinámico en constante cambio. Lo que realmente nos diferencia, es estar comprometidos genuinamente con tu crecimiento a todo nivel.
            </p>
            <div className='wow fadeInUp animated'>
              <img src="imagenes/franquiciarg-logo.png" alt="franquiciarg desarrollo de franquicias" tabIndex="0"></img>
            </div>
            <div className="contBtn wow fadeInUp animated">
              <a className="nav-link nav_link_color" href="#contacto">Contactanos</a>
            </div>
          </div>
        </div>

      </div>
    </section>

  );
}

export default SECCION_UNO;