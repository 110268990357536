import 'bootstrap/dist/css/bootstrap.min.css';


function SECCION_DOS() {
return (

<section className="seccion_dos" id='servicios'  /*style={{backgroundImage: 'url(imagenes/fondo_servicios.png)',backgroundRepeat: 'no-repeat'}}*/ >
  <div className="container">
    <div>
      <div className="title wow fadeInDown animated">
        <h4 className="title" tabIndex="0">Servicios</h4>
        <hr></hr>
      </div>
      <div className="conteDos">
        <div className="row">
          <div className="col-6 wow fadeInLeft animated">
            <div>
              <h5 className="" tabIndex="0"> Comercialización de Franquicias:</h5>
            </div>
            <div>
              <p tabIndex="0" >Comercializaremos tus franquicias de manera seria, profesional y respetando no solo tu idiosincrasia, sino que además nos involucramos como parte de tu equipo.</p>
              <p tabIndex="0" >Te ofrecemos 2 formatos para que elijas; el primero es trabajar tus propios leads para evitarte contratar un recurso interno, y el segundo es generarte leads mediante campañas en Meta y Google Ads.</p>
              <p tabIndex="0" >Las dos  opciones incluyen asesoramiento en locaciones, ubicaciones geográficas, comunicación y en materia de franquicias en general. En este servicio es fundamental tus ganas de crecer como marca, y lograr juntos una sinergia que luego muestre resultados.</p>
            </div>
          </div>
          <div className="col-6 primerImagen wow fadeInRight animated">
            <img alt="comercialización de franquicias" src="imagenes/comercia_franquicias.jpg" tabIndex="0"></img>
          </div>
        </div>
        <div className="row">
          <div className="col-6 segundaImagen wow fadeInLeft animated">
              <img alt="Desarrollo de Franquicias" src="imagenes/desarrollo-franquicias.jpg" tabIndex="0"></img>
          </div>
          <div className="col-6 wow fadeInRight animated">
            <div>
              <h5 className="" tabIndex="0"> Desarrollo de Franquicias: </h5>
            </div>
            <div>
              <p tabIndex="0">
                Te acompañamos, asistimos y preparamos en el sistema de franquicias para que tengas un crecimiento sostenible en el tiempo, haciendo un fuerte hincapié en el branding y el modelo a replicar, entre otras cosas. Dentro de esta preparación nos involucramos de lleno en la marca y te ayudamos en tu visión de la misma hacia el futuro, trabajando junto a vos en todo tipo de mejoras, inclusive en la forma de pensar y gestionar tu modelo de negocio.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 wow fadeInLeft animated">
            <div>
              <h5 className="" tabIndex="0"> Asesoramiento a Inversores: </h5>
            </div>
            <div>
              <p tabIndex="0">
                  Si estás interesado en adquirir una franquicia, pero aún no definiste ni la marca ni el sector, te ofrecemos un servicio personalizado. El mismo consta de una evaluación de tu perfil, seguida de una búsqueda en conjunto de franquicias que se alineen perfectamente con tus recursos, objetivos, intereses, necesidades, y más importante con tu personalidad.
              </p>
              <p tabIndex="0">
              <b>
                Tené en cuenta que es crucial tomar una decisión con fundamentos, ya que no hacerlo puede resultar “muy caro” a futuro.
              </b>
              </p>
            </div>
          </div>

          <div className="col-6 tercerImagen wow fadeInRight animated">
              <img alt="Asesoramiento a Inversores" src="imagenes/asesoria-inversores.jpg" tabIndex="0"></img>
          </div>
        </div>
      </div>
      <div className="contBtn">
              <a className="nav-link nav_link_color" href="#contacto">Contactanos</a>
            </div>
    </div>

  </div>
</section>

);
}

export default SECCION_DOS;