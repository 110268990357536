import 'bootstrap/dist/css/bootstrap.min.css';
import { swiffyslider } from 'swiffy-slider'
import 'swiffy-slider/css';


function SECCION_TRES() {
    window.swiffyslider = swiffyslider;

    window.addEventListener("load", () => {
        window.swiffyslider.init();
    });

  return (

    <div className="seccion_tres" id="franquicias">
      <div className="container">
          <div>
            <div>
              <h4 className="title">
                Franquicias
              </h4>
              <hr></hr>
            </div>
            <div className='fraseMobile'>
                <p>Desplazá hacia los laterales para ver las Marcas</p> 
            </div>

                <div class="swiffy-slider slider-item-show2-sm slider-item-reveal slider-nav-dark slider-nav-outside slider-nav-visible slider-nav-page slider-nav-autoplay slider-indicators-outside slider-indicators-sm  slider-nav-autopause" data-slider-nav-autoplay-interval="6300">
                    <ul class="slider-container">
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Woolen"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/wollen-franquicias.jpg"  alt="wollen-franquicias"></img>
                            <h4>Wollen</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Ominim"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/ominim-franquicias.jpg"  alt="ominim franquicias"></img>
                            <h4>Ominim</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Verkar"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/verkar-franquicias.jpg"  alt="verkar franquicias"></img>
                            <h4>Verkar</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Tomasso"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/tomasso-franquicias.jpg"  alt="tomasso-franquicias"></img>
                            <h4>Tomasso</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia SubWay"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/subway-franquicias.jpg"  alt="subway-franquicias"></img>
                            <h4>SubWay</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Saverio"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/saverio-franquicias.jpg"  alt="saverio-franquicias"></img>
                            <h4>Saverio</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Planeta Empanada"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/planeta-empanada-franquicias.jpg"  alt="planeta-empanada-franquicias"></img>
                            <h4>Planeta Empanada</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Margherita"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/margherita-franquicias.jpg"  alt="margherita-franquicias"></img>
                            <h4>Margherita</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Adaggio"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/adaggio-franquicias.jpg"  alt="adaggio-franquicias"></img>
                            <h4>Adaggio</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Lo mas Rico"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/lo-mas-rico-franquicias.jpg"  alt="lo-mas-rico-franquicias"></img>
                            <h4>Lo mas Rico</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia La Guitarrita"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/la-guitarrita-franquicias.jpg"  alt="la-guitarrita-franquicias"></img>
                            <h4>La Guitarrita</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia KOI"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/koi-franquicias.jpg"  alt="koi-franquicias"></img>
                            <h4>KOI</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Kirichocho"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/kiricocho-franquicias.jpg"  alt="kiricocho-franquicias"></img>
                            <h4>Kiricocho</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Woolen"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/wollen-franquicias.jpg"  alt="Woolen franquicias"></img>
                            <h4>Wollen</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Ominim"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/ominim-franquicias.jpg"  alt="ominim franquicias"></img>
                            <h4>Ominim</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Verkar"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/verkar-franquicias.jpg"  alt="verkar franquicias"></img>
                            <h4>Verkar</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Tomasso"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/tomasso-franquicias.jpg"  alt="tomasso-franquicias"></img>
                            <h4>Tomasso</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia SubWay"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/subway-franquicias.jpg"  alt="subway-franquicias"></img>
                            <h4>SubWay</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Saverio"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/saverio-franquicias.jpg"  alt="saverio-franquicias"></img>
                            <h4>Saverio</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Planeta Empanada"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/planeta-empanada-franquicias.jpg"  alt="planeta-empanada-franquicias"></img>
                            <h4>Planeta Empanada</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Margherita"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/margherita-franquicias.jpg"  alt="margherita-franquicias"></img>
                            <h4>Margherita</h4>
                            </a> 
                        </li>
                        <li>
                        <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Adaggio"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/adaggio-franquicias.jpg"  alt="adaggio-franquicias"></img>
                        <h4>Adaggio</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Lo mas Rico"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/lo-mas-rico-franquicias.jpg"  alt="lo-mas-rico-franquicias"></img>
                            <h4>Lo mas Rico</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia La Guitarrita"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/la-guitarrita-franquicias.jpg"  alt="la-guitarrita-franquicias"></img>
                            <h4>La Guitarrita</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia KOI"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/koi-franquicias.jpg"  alt="koi-franquicias"></img>
                            <h4>KOI</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Kirichocho"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/kiricocho-franquicias.jpg"  alt="kiricocho-franquicias"></img>
                            <h4>Kiricocho</h4>
                            </a> 
                        </li>
                    </ul>
                    <button type="button" className="slider-nav"></button>
                    <button type="button" className="slider-nav slider-nav-next"></button>                    
                </div>   

                <div class="swiffy-slider slider-item-show2-sm slider-item-reveal slider-nav-dark slider-nav-outside slider-nav-visible slider-nav-page slider-nav-autoplay slider-indicators-outside slider-indicators-sm slider-nav-autopause" data-slider-nav-autoplay-interval="6300">
                    <ul class="slider-container">
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Kentucky"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/kentucky-franquicias.jpg"  alt="kentucky-franquicias"></img>
                            <h4>Kentucky</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Hausbrot"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/hausbrot-franquicias.jpg"  alt="hausbrot-franquicias"></img>
                            <h4>Hausbrot</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Happens"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/happens-franquicias.jpg"  alt="happens-franquicias"></img>
                            <h4>Happens</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Green & Co."  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/green-and-co-franquicias.jpg"  alt="green-and-co-franquicias"></img>
                            <h4>Green & Co.</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Go Fix"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/go-fix-franquicias.jpg"  alt="go-fix-franquicias"></img>
                            <h4>Go Fix</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Eyelit"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/eyelit-franquicias.jpg"  alt="eyelit-franquicias"></img>
                            <h4>Eyelit</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Empanadas Mayoristas"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/empanadas-mayoristas-franquicias.jpg"  alt="empanadas-mayoristas-franquicias"></img>
                            <h4>Empanadas Mayoristas</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia El Abastecedor"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/el-abastecedor-franquicias.jpg"  alt="el-abastecedor-franquicias"></img>
                            <h4>El Abastecedor</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia D-Uñas"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/d-unias-franquicias.jpg"  alt="d-unias-franquicias"></img>
                            <h4>D-Uñas</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia DIA"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/dia-franquicias.jpg"  alt="dia-franquicias"></img>
                            <h4>DIA</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia El Desembarco"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/el-desembarco-franquicias.jpg"  alt="el-desembarco-franquicias"></img>
                            <h4>El Desembarco</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Dulce de Leche and Co."  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/dulce-de-leche-and-co-franquicias.jpg"  alt="dulce-de-leche-and-co-franquicias"></img>
                            <h4>Dulce de Leche & Co.</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Residencias Universitarias"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/residencias-ciudad-universitaria-franquicias.jpg"  alt="residencias-ciudad-universitaria-franquicias"></img>
                            <h4>Residencias Universitarias</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Kentucky"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/kentucky-franquicias.jpg"  alt="kentucky-franquicias"></img>
                            <h4>Kentucky</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Hausbrot"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/hausbrot-franquicias.jpg"  alt="hausbrot-franquicias"></img>
                            <h4>Hausbrot</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Happens"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/happens-franquicias.jpg"  alt="happens-franquicias"></img>
                            <h4>Happens</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Green & Co."  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/green-and-co-franquicias.jpg"  alt="green-and-co-franquicias"></img>
                            <h4>Green & Co.</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Go Fix"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/go-fix-franquicias.jpg"  alt="go-fix-franquicias"></img>
                            <h4>Go Fix</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Eyelit"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/eyelit-franquicias.jpg"  alt="eyelit-franquicias"></img>
                            <h4>Eyelit</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Empanadas Mayoristas"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/empanadas-mayoristas-franquicias.jpg"  alt="empanadas-mayoristas-franquicias"></img>
                            <h4>Empanadas Mayoristas</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia El Abastecedor"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/el-abastecedor-franquicias.jpg"  alt="el-abastecedor-franquicias"></img>
                            <h4>El Abastecedor</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia D-Uñas"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/d-unias-franquicias.jpg"  alt="d-unias-franquicias"></img>
                            <h4>D-Uñas</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia DIA"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/dia-franquicias.jpg"  alt="dia-franquicias"></img>
                            <h4>DIA</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia El Desembarco"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/el-desembarco-franquicias.jpg"  alt="el-desembarco-franquicias"></img>
                        <h4>El Desembarco</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Dulce de Leche and Co."  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/dulce-de-leche-and-co-franquicias.jpg"  alt="dulce-de-leche-and-co-franquicias"></img>
                            <h4>Dulce de Leche & Co.</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Residencias Universitarias"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/residencias-ciudad-universitaria-franquicias.jpg"  alt="residencias-ciudad-universitaria-franquicias"></img>
                            <h4>Residencias Universitarias</h4>
                            </a> 
                        </li>
                    </ul>

                    <button type="button" className="slider-nav"></button>
                    <button type="button" className="slider-nav slider-nav-next"></button>                    
                </div> 


                <div class="swiffy-slider slider-item-show2-sm slider-item-reveal slider-nav-dark slider-nav-outside slider-nav-visible slider-nav-page slider-nav-autoplay slider-indicators-outside slider-indicators-sm slider-nav-autopause" data-slider-nav-autoplay-interval="6300">
                    <ul class="slider-container">
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Daniel"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/daniel-franquicias.jpg"  alt="daniel-franquicias"></img>
                            <h4>Daniel</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Dandy"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/dandy-franquicias.jpg"  alt="dandy-franquicias"></img>
                            <h4>Dandy</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Fabric"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/fabric-franquicias.jpg"  alt="fabric-franquicias"></img>
                            <h4>Fabric</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia KFC"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/kfc-franquicias.jpg"  alt="kfc-franquicias"></img>
                            <h4>KFC</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Wendy's"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/wendys-franquicias.jpg"  alt="wendys-franquicias"></img>
                            <h4>Wendy's</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia E.M."  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/electrica-mosconi-franquicias.jpg"  alt="electrica-mosconi-franquicias"></img>
                            <h4>Electrica Mosconi</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Chungo"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/chungo-franquicias.jpg"  alt="chungo-franquicias"></img>
                            <h4>Chungo</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Catapumba"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/catapumba-franquicias.jpg"  alt="catapumba-franquicias"></img>
                            <h4>Catapumba</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Brozziano"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/brozziano-franquicias.jpg"  alt="brozziano-franquicias"></img>
                            <h4>Brozziano</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Antares"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/antares-franquicias.jpg"  alt="antares-franquicias"></img>
                            <h4>Antares</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Alot"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/alot-franquicias.jpg"  alt="alot-franquicias"></img>
                            <h4>Alot</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Don Antonio"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/don-antonio-franquicias.jpg"  alt="don-antonio-franquicias"></img>
                            <h4>Don Antonio</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Daniel"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/daniel-franquicias.jpg"  alt="daniel-franquicias"></img>
                            <h4>Daniel</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Dandy"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/dandy-franquicias.jpg"  alt="dandy-franquicias"></img>
                            <h4>Dandy</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Fabric"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/fabric-franquicias.jpg"  alt="fabric-franquicias"></img>
                            <h4>Fabric</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia KFC"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/kfc-franquicias.jpg"  alt="kfc-franquicias"></img>
                            <h4>KFC</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Wendy's"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/wendys-franquicias.jpg"  alt="wendys-franquicias"></img>
                            <h4>Wendy's</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia E.M."  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/electrica-mosconi-franquicias.jpg"  alt="electrica-mosconi-franquicias"></img>
                            <h4>Electrica Mosconi</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Chungo"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/chungo-franquicias.jpg"  alt="chungo-franquicias"></img>
                            <h4>Chungo</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Catapumba"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/catapumba-franquicias.jpg"  alt="catapumba-franquicias"></img>
                            <h4>Catapumba</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Brozziano"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/brozziano-franquicias.jpg"  alt="brozziano-franquicias"></img>
                            <h4>Brozziano</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Antares"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/antares-franquicias.jpg"  alt="antares-franquicias"></img>
                            <h4>Antares</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Alot"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/alot-franquicias.jpg"  alt="alot-franquicias"></img>
                            <h4>Alot</h4>
                            </a> 
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text=¡Hola!, quiero mas informaión sobre la franquicia Don Antonio"  alt="carrusel logos" target="_blank" rel="noreferrer"><img src="https://www.franquiciarg.com.ar/imagenes/logos/don-antonio-franquicias.jpg"  alt="don-antonio-franquicias"></img>
                            <h4>Don Antonio</h4>
                            </a> 
                        </li>
                    </ul>

                    <button type="button" className="slider-nav"></button>
                    <button type="button" className="slider-nav slider-nav-next"></button>                    
                </div> 

            </div>

      </div>
    </div>

  );
}

export default SECCION_TRES;