import React from 'react';
import ReactDOM from 'react-dom/client';
//import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

  if (root) {
    root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
    );
  
  } else {
    console.error("Elemento raíz no encontrado");
  }
 


/* Busca el elemento con el id 'root'
const rootElement = document.getElementById('root');
if (rootElement) {

  // Crea la raíz con React 18
  const root = createRoot(rootElement);

  // Renderiza la aplicación
  root.render(<App />);
} else {
  console.error("Elemento raíz no encontrado");
}
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals 
reportWebVitals();
