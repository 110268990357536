import 'bootstrap/dist/css/bootstrap.min.css';


function FOOTER() {
    return (
        <div>
                <footer id="footerDos" className="animado wow fadeIn animated">
                    <div className="container">                
                        {/*<div className="footer1">
                            <p className="tituContac">Datos de contacto</p>                
                        </div> */}
                        <div className="footer2">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-3">
                                        <div className="navFooter">
                                            <div>
                                                <img className="logoFuter" src="imagenes/franquiciarg-logo-blanco.png" alt="franquiciarg asesoria franquicias" tabIndex="0"></img>
                                            </div>
                                            <ul>
                                                <li></li>
                                                <li className="nav-futer">
                                                    <a className="nav-link nav_link_color" href="#quienes">¿Quiénes somos?</a>
                                                </li>
                                                <li className="nav-futer">
                                                <a className="nav-link nav_link_color" href="#servicios ">Servicios </a>
                                                </li>
                                                <li className="nav-futer">
                                                <a className="nav-link nav_link_color" href="#franquicias ">Franquicias </a>
                                                </li>
                                                <li className="nav-futer">
                                                    <a className="nav-link nav_link_color" href="#contacto">Contacto</a>
                                                </li>   
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div className="col-3">
                                        <div className="dtsUbic">
                                            <div className="map">
                                                <span> Info Contacto: </span>
                                                {/*<div className="celu">
                                                    <a href="tel:1134635951" alt="link" title="Llamar por teléfono">
                                                        <i className="fa-solid fa-phone"></i> 11 3463 5951 - Llamanos
                                                    </a>
                                                </div>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.8267345016466!2d-58.50016422339284!3d-34.58325045641908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb64519dda089%3A0xec171a54a9331666!2sEl%C3%A9ctrica%20Mosconi!5e0!3m2!1ses!2sar!4v1715112896648!5m2!1ses!2sar" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>*/}
                                                <div className="conteDire">
                                                    <ul className="listaDire">
                                                        <li><i class="fa-brands fa-whatsapp"></i> +54 9 11 3463 5951</li>
                                                        <li><i className="fa-solid fa-envelope"></i>  info@franquiciarg.com.ar</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="conteRsFooter">
                                            <span> Seguinos en: </span>
                                            <ul>
                                                <li>
                                                    <a className="nav-link linkeicon" href="https://www.linkedin.com/company/franquiciarg/" alt="link" target="_blank" title="linkedin" rel="noreferrer">
                                                        <i className="fa-brands fa-linkedin"></i> Linkedin
                                                    </a>
                                                </li>
                                                { /*<li>
                                                    <a className="redes " href="https://www.facebook.com/electricamosconi" alt="link" target="_blank" title="facebook"><i className="fa-brands fa-facebook"></i>
                                                    Facebook
                                                    </a>
                                                </li>/*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </footer>
                <div className="posfuter">
                <div className="container">
                    <div className="row justify-content-between align-items-center">                    
                        <div className="col-6">
                            <p>© 2024 - Todos los derechos reservados</p>
                        </div>
                        <div className="col-6">
                            <p>Diseño y Desarrollo <a href="http://bluesitesweb.com" target="_blank" rel="noopener noreferrer"> <img src="imagenes/logo_footer.png" alt="imagen"></img></a></p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    );
  }
  
  export default FOOTER;