import 'bootstrap/dist/css/bootstrap.min.css';


function SECCION_CINCO() {
  return (

    <section className="seccion_cinco">
      <div className="container">
        <div className="row-column">
          <div>
            <h3 tabIndex="0">En <b>FranquiciArg</b>, <b>tu crecimiento</b> es nuestro <b>compromiso.</b></h3>
            <h4 tabIndex="0">¡Hasta pronto!</h4>
          </div>
        </div>
      </div>
    </section>

  );
}

export default SECCION_CINCO;